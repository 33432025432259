/* import __COLOCATED_TEMPLATE__ from './tier-selector.hbs'; */
/* RESPONSIBLE TEAM: team-purchase-experience */
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { type Block } from 'embercom/models/data/pricing/tier-types';
import { inject as service } from '@ember/service';
import type IntershopService from 'embercom/services/intershop-service';

interface Args {
  metric: string;
  index: number;
  onChange: (index: number) => {};
  blocks: Array<Block>;
  isPlanInSubscription: boolean;
  isPlanOnTrial: boolean;
  hasActivePlanOnProduct: boolean;
}

export default class TierSelectorComponent extends Component<Args> {
  @service declare intershopService: IntershopService;
  @service declare appService: any;

  constructor(owner: unknown, args: Args) {
    super(owner, args);
    this.select(
      this.args.hasActivePlanOnProduct && this.isInOverages
        ? this.recommendedBlockIndex
        : this.args.index,
    );
  }

  get selectedBlockIndex(): number {
    return this.args.index;
  }

  @action
  select(index: number): void {
    this.args.onChange(index);
    this.scrollToSelectedBlock();
  }

  @action
  onSelectionChanged(): void {
    this.scrollToSelectedBlock();
  }

  private scrollToSelectedBlock(): void {
    // timeout is required as a workaround for scrollIntoView appearing to not work
    // correctly when trigged by onChange event when defocusing an input
    setTimeout(() => {
      let element = document.getElementById(`pricing-block-${this.selectedBlockIndex}`);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth', inline: 'center' });
      }
    }, 1);
  }

  get isInOverages(): boolean {
    return this.totalUsage > this.contractedUsage;
  }

  get contractedUsage(): number {
    return this.intershopService.contractUsageLimits[this.args.metric] ?? 0;
  }

  get totalUsage(): number {
    return this.intershopService.totalUsage[this.args.metric] ?? 0;
  }

  get contractedBlockIndex(): number {
    if (this.contractedUsage === 0) {
      return -1;
    }

    return this.args.blocks.findIndex((block) => {
      return (
        block.starting_unit <= this.contractedUsage && block.ending_unit >= this.contractedUsage
      );
    });
  }

  get contractedPrice(): number | undefined {
    if (this.contractedUsage === 0) {
      return 0;
    }

    return this.args.blocks.find((block) => {
      return (
        block.starting_unit <= this.contractedUsage && block.ending_unit >= this.contractedUsage
      );
    })?.cumulative_price;
  }

  // Should select and display “Recommended” as the next highest tier if they are not in overages.
  // If in overages the tier that fits the overage value should be selected as “Recommended”
  get recommendedBlockIndex(): number {
    if (this.contractedUsage >= this.totalUsage) {
      return this.contractedBlockIndex + 1;
    }

    return this.args.blocks.findIndex((block) => {
      return block.starting_unit <= this.totalUsage && block.ending_unit >= this.totalUsage;
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Intershop::Pricing::PricingMetrics::UsageSelector::TierSelector::TierSelector': typeof TierSelectorComponent;
    'intershop/pricing/pricing-metrics/usage-selector/tier-selector/tier-selector': typeof TierSelectorComponent;
  }
}
