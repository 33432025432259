/* import __COLOCATED_TEMPLATE__ from './headline.hbs'; */
/* RESPONSIBLE TEAM: team-purchase-experience */
import Component from '@glimmer/component';
// @ts-ignore
import { addonData } from 'embercom/lib/intershop/intershop';
import type Plan from 'embercom/models/plan';

interface Args {
  plan: Plan;
}

export default class HeadlineComponent extends Component<Args> {
  get productData() {
    return addonData(this.args.plan.id);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Intershop::Products::AddOns::Overview::Headline': typeof HeadlineComponent;
    'intershop/products/add-ons/overview/headline': typeof HeadlineComponent;
  }
}
