/* import __COLOCATED_TEMPLATE__ from './intersection-launcher.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable @intercom/intercom/no-default-task-ember-concurrency */
/* eslint-disable @intercom/intercom/require-snake-case-analytics */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable ember/no-jquery */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import Component from '@ember/component';
import { inject as service } from '@ember/service';
import { isBlank } from '@ember/utils';
import $ from 'jquery';
import { get, action } from '@ember/object';
import { INCORRECT_ORIGIN } from '../services/intersection-service';
import { task } from 'ember-concurrency';
import { objectTypes } from 'embercom/models/data/matching-system/matching-constants';

export default Component.extend({
  store: service(),
  intersectionService: service(),
  intercomEventService: service(),
  router: service(),
  intersectionMode: 'tour-editor',

  didInsertElement() {
    this._super(...arguments);
    this.intersectionService.registerEventHandler('track-analytics-event', (data) => {
      this.trackIntersectionEvent(data);
      return true;
    });
  },

  willDestroyElement() {
    this._super(...arguments);
    this.intersectionService.unregisterEventHandler('track-analytics-event');
    $(window).off('message.tour-splash-screen');
  },

  handlePostMessage(event) {
    if (get(event, 'origin') !== window.location.origin) {
      return INCORRECT_ORIGIN;
    }

    if (get(event, 'data.updatedURLForTour')) {
      let updatedUrl = event.data.updatedURLForTour;
      this.updateTourUrlAndStepUrls.perform(updatedUrl);
      this.openTourBuilder(updatedUrl);
      $(window).off('message.tour-splash-screen');
    } else if (get(event, 'data.urlForTourBuilder')) {
      this.openTourBuilder(event.data.urlForTourBuilder);
      $(window).off('message.tour-splash-screen');
      this.intersectionService.waitForMessenger(objectTypes.tour);
    } else if (get(event, 'data.urlForTooltipsBuilder')) {
      this.openTooltipsBuilder(event.data.urlForTooltipsBuilder);
      $(window).off('message.tour-splash-screen');
      this.intersectionService.waitForMessenger(objectTypes.tooltip);
    } else if (get(event, 'data.installMessenger')) {
      this.router.transitionTo('apps.app.settings.channels.messenger.web');
    }
  },

  trackIntersectionEvent(event) {
    this.intercomEventService.trackAnalyticsEvent(event);
  },

  updateTourUrlAndStepUrls: task(function* (updatedUrl) {
    let steps = this.tour.steps.forEach((step) => (step.url = updatedUrl));
    this.tour.setProperties({ url: updatedUrl, steps });
    yield this.tour.save();
  }),

  openTourPreview: action(function (url) {
    this.intersectionService.openWindow(url, 'tour-preview');
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: 'open-tour-preview-website',
    });
  }),

  openTourSplashScreen: action(function () {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'viewed',
      object: 'tour_splash_screen',
    });
    this.intersectionService.openTourSplashScreen();
    //eslint-disable-next-line @intercom/intercom/no-jquery-window-on
    $(window).on('message.tour-splash-screen', (event) =>
      this.handlePostMessage(event.originalEvent),
    );
  }),

  openTooltipsSplashScreen: action(function () {
    this.intersectionService.openTooltipsSplashScreen();
    //eslint-disable-next-line @intercom/intercom/no-jquery-window-on
    $(window).on('message.tour-splash-screen', (event) =>
      this.handlePostMessage(event.originalEvent),
    );
  }),

  openTourURLUpdateSplashScreen: action(function () {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'viewed',
      object: 'tour_splash_screen',
    });
    this.intersectionService.openTourURLUpdateSplashScreen(this.tour.url);
    //eslint-disable-next-line @intercom/intercom/no-jquery-window-on
    $(window).on('message.tour-splash-screen', (event) =>
      this.handlePostMessage(event.originalEvent),
    );
  }),

  openTourBuilder: action(function (url) {
    if (isBlank(url)) {
      this.openTourSplashScreen();
    } else {
      this.intercomEventService.trackAnalyticsEvent({
        action: 'clicked',
        object: 'open-tour-editor-website',
      });
      this.intersectionService.openWindow(url, this.intersectionMode);
    }
  }),

  openTooltipsBuilder: action(function (url) {
    if (isBlank(url)) {
      this.openTooltipsSplashScreen();
    } else {
      this.intersectionService.openWindow(url, this.intersectionMode);
    }
  }),
});
