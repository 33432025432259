/* import __COLOCATED_TEMPLATE__ from './pricing-summary-modal.hbs'; */
/* RESPONSIBLE TEAM: team-purchase-experience */
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { addonCardData } from 'embercom/lib/intershop/intershop';

import type IntlService from 'embercom/services/intl';
import type CatalogApi from 'embercom/services/catalog-api';
import { type TaskGenerator } from 'ember-concurrency';
import type Product from 'embercom/models/product';
import type Plan from 'embercom/models/plan';
import { planCardData } from 'embercom/lib/intershop/intershop';
import type IntershopService from 'embercom/services/intershop-service';

interface Args {
  onModalClose: () => void;
  isOpen: boolean;
  product: Product;
  plan: Plan;
  requestSubscriptionAmendment: TaskGenerator<void>;
  isPlanInSubscription: boolean;
}

export default class PricingSummaryModalComponent extends Component<Args> {
  @service declare intl: IntlService;
  @service declare catalogApi: CatalogApi;
  @service declare intershopService: IntershopService;
  @service declare appService: any;

  get planDetails() {
    return this.args.product.addon
      ? {
          ...addonCardData(this.args.plan.id),
          description: addonCardData(this.args.plan.id).addonProductPageDescription,
        }
      : {
          ...planCardData(this.args.plan.id),
          icon: planCardData(this.args.plan.id).planSvg,
        };
  }

  get activePlanOnProduct(): string | undefined {
    return this.args.product.billableCustomerPlan?.nameWithProduct;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Intershop::Modals::PricingSummary::Order::PricingSummaryModal': typeof PricingSummaryModalComponent;
    'intershop/modals/pricing-summary/order/pricing-summary-modal': typeof PricingSummaryModalComponent;
  }
}
