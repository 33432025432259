/* import __COLOCATED_TEMPLATE__ from './plan-pricing-metric.hbs'; */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* RESPONSIBLE TEAM: team-purchase-experience */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type IntlService from 'embercom/services/intl';
import type IntershopService from 'embercom/services/intershop-service';

import PricingMetric from 'embercom/lib/purchase/pricing-metric';
import { type Metric } from 'embercom/models/data/pricing/metric-types';
import type Plan from 'embercom/models/plan';
import type { Breakdown } from 'lib/factory-generator/types/price';
import type { PricingTooltipBaseIncludes } from 'embercom/models/data/pricing/pricing-summary-types';

interface Args {
  plan: Plan;
  pricingMetric: PricingMetric;
  includedSeatUsage: PricingTooltipBaseIncludes | undefined;
  selectedMetricsUsage: Partial<Record<Metric, number>>;
}

export default class PlanPricingMetricComponent extends Component<Args> {
  @service declare intl: IntlService;
  @service declare intershopService: IntershopService;

  get pricePerUnit(): string {
    return this.intershopService.formatPriceFromCents(this.args.pricingMetric.perUnitPrice);
  }

  get pricePerUnitWithPrecision(): string {
    return this.intershopService.formatPriceFromCents(this.args.pricingMetric.perUnitPrice, 2);
  }

  get useIncludedUsage(): boolean {
    return (
      !!this.args.includedSeatUsage &&
      !!this.args.includedSeatUsage.usage &&
      this.args.pricingMetric.isSeatMetric
    );
  }

  get formattedTotal(): string {
    if (this.args.pricingMetric.isSeatMetric) {
      return this.intershopService.formatPriceFromCents(
        this.args.pricingMetric.perUnitPrice * this.usage,
      );
    } else {
      return this.intershopService.formatPriceFromCents(this.args.pricingMetric.price);
    }
  }

  get usage(): number {
    if (this.args.pricingMetric.isSeatMetric) {
      if (
        !!this.args.includedSeatUsage &&
        !!this.args.includedSeatUsage.usage &&
        this.args.pricingMetric.isSeatMetric
      ) {
        return (
          this.args.selectedMetricsUsage[this.args.pricingMetric.metric]! -
          this.args.includedSeatUsage.usage
        );
      }
      return this.args.selectedMetricsUsage[this.args.pricingMetric.metric]!;
    } else {
      return this.args.pricingMetric.usage;
    }
  }

  get additionalSeats(): number {
    // Additional seats are the difference between the overages and the selected amount
    let billedUsage = this.intershopService.getMetricBilledUsage(this.args.pricingMetric.metric);
    return billedUsage
      ? billedUsage - this.args.selectedMetricsUsage[this.args.pricingMetric.metric]!
      : 0;
  }

  get additionalFormattedTotal(): string {
    return this.intershopService.formatPriceFromCents(
      this.additionalSeats * this.args.pricingMetric.perUnitPrice,
    );
  }

  get overlappingSeatPlans(): Array<string> {
    return this.intershopService.newPriceBreakdown.filter((breakdown: Breakdown) => {
      let pricingMetric = new PricingMetric(breakdown.charges.firstObject);
      return breakdown.plan_id !== this.args.plan.idAsNumber && pricingMetric.isSeatMetric;
    });
  }

  get overlappingUsagePlans(): Array<string> {
    return this.intershopService.newPriceBreakdown.filter((breakdown: Breakdown) => {
      let pricingMetric = new PricingMetric(breakdown.charges.firstObject);
      // Exclude people reached plans from displaying as overlapping plans with additional cost
      return (
        !this.intershopService.PEOPLE_REACHED_TIER_IDS_AS_NUMBERS.includes(
          Number(breakdown.plan_id),
        ) &&
        breakdown.plan_id !== this.args.plan.idAsNumber &&
        !pricingMetric.isSeatMetric
      );
    });
  }

  get hasLinearPricing(): boolean {
    return this.args.pricingMetric.hasLinearPricing;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Intershop::Pricing::PricingSummary::Order::Tooltip::PlanPricingMetric': typeof PlanPricingMetricComponent;
    'intershop/pricing/pricing-summary/order/tooltip/plan-pricing-metric': typeof PlanPricingMetricComponent;
  }
}
