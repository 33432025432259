/* RESPONSIBLE TEAM: unused-components */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable ember/no-observers */
/* eslint-disable ember/no-on-calls-in-components */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable ember/no-jquery */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import { isPresent } from '@ember/utils';
import $ from 'jquery';
import { schedule } from '@ember/runloop';
import { observer } from '@ember/object';
import { on } from '@ember/object/evented';
import { not, and } from '@ember/object/computed';
import Component from '@ember/component';
import ScrollManager from 'embercom/components/mixins/scroll-manager';
import CloseOnDocumentClickManager from 'embercom/components/mixins/close-on-document-click-manager';

export default Component.extend(ScrollManager, CloseOnDocumentClickManager, {
  closeOnScroll: false,
  isFadingOut: false,
  isNotFadingOut: not('isFadingOut'),
  performScrollFadeOut: and('isNotFadingOut', 'closeOnScroll', 'isOpen'),

  dropdownMinWidth: 0,
  dropdownMinHeight: 0,

  dropdownMaxWidth: 300,
  dropdownMaxHeight: 500,

  disabled: false,

  //Used when the stacking context has been altered through transforms
  translateOffsetX: 0,
  translateOffsetY: 0,

  setupScrollListener: on('didInsertElement', function () {
    this.bindToScrollParent(this._calculateScrollPosition);
  }),

  teardownScrollListener: on('willDestroyElement', function () {
    this.unbindFromScrollParent();
  }),

  onOpenStateChange: observer({
    dependentKeys: ['isOpen'],

    fn() {
      if (this.isOpen) {
        this._calculateDropdownPositioning();
      }
    },

    sync: true,
  }),

  _calculateScrollPosition() {
    if (this.performScrollFadeOut) {
      this.set('isFadingOut', true);
      $('.dropdown', this.element).fadeOut(100, () => {
        if (!this.isDestroying) {
          this.setProperties({
            isOpen: false,
            isFadingOut: false,
          });
        }
      });
    }
    this._calculateDropdownPositioning();
  },

  _calculateDropdownPositioning() {
    if (this.isOpen && !this.isDestroying) {
      schedule('afterRender', () => {
        let $selectBox = this.selectBoxSelector
          ? $(this.selectBoxSelector, this.element)
          : $(this.element);
        let $dropdown = $('.dropdown', this.element);

        let selectBoundingRect = $selectBox[0].getBoundingClientRect();
        let windowHeight = window.innerHeight;
        let windowWidth = window.innerWidth;

        let translateOffsetX = this.translateOffsetX;
        let translateOffsetY = this.translateOffsetY;

        let dropdownHeight = Math.max(
          this.dropdownMinHeight,
          Math.min(this.dropdownMaxHeight, $dropdown.outerHeight()),
        );
        let dropdownWidth = Math.max(
          this.dropdownMinWidth,
          Math.min(this.dropdownMaxWidth, $dropdown.outerWidth()),
        );

        let top =
          selectBoundingRect.bottom + dropdownHeight < windowHeight
            ? selectBoundingRect.bottom
            : selectBoundingRect.top - dropdownHeight;
        if (top < 0) {
          top = selectBoundingRect.bottom;
        }
        if (translateOffsetY) {
          top += translateOffsetY;
        }

        let left = 0;
        let leftAlignedLocation = selectBoundingRect.left;
        let rightAlignedLocation = selectBoundingRect.right - dropdownWidth;

        if (this.rightAligned) {
          left = rightAlignedLocation > 0 ? rightAlignedLocation : leftAlignedLocation;
        } else {
          left =
            leftAlignedLocation + dropdownWidth < windowWidth
              ? leftAlignedLocation
              : rightAlignedLocation;
        }
        if (translateOffsetX) {
          left += translateOffsetX;
        }

        $dropdown.css({
          position: 'fixed',
          top: `${top}px`,
          left: `${left}px`,
          height: `${dropdownHeight}px`,
          width: `${dropdownWidth}px`,
          minWidth: `${dropdownWidth}px`,
          overflow: 'auto',
        });
      });
    }
  },

  actions: {
    toggleOpenState() {
      if (this.disabled === true) {
        return;
      }
      this.toggleProperty('isOpen');
      if (isPresent(this.trigger)) {
        this.trigger('didChangeOpenState');
      }
      if (this.isOpen) {
        this._calculateDropdownPositioning();
      }
    },
  },
});
