/* import __COLOCATED_TEMPLATE__ from './pricing-summary.hbs'; */
/* RESPONSIBLE TEAM: team-purchase-experience */
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import type Plan from 'embercom/models/plan';
import type Product from 'embercom/models/product';
import type IntlService from 'embercom/services/intl';
import type IntershopService from 'embercom/services/intershop-service';
import { type Metric } from 'embercom/models/data/pricing/metric-types';
import { action } from '@ember/object';

interface Args {
  plan: Plan;
  product: Product;
  openSummaryModal: () => void;
  isPlanInSubscription: boolean;
  disableCheckoutButton: boolean;
  selectedMetricsUsage: Partial<Record<Metric, number>>;
}

export default class PricingSummaryComponent extends Component<Args> {
  @service customerService: any;
  @service intercomEventService: any;
  @service declare intershopService: IntershopService;
  @service declare intl: IntlService;
  @service declare appService: any;

  get activePlanOnProduct(): string | undefined {
    return this.args.product.billableCustomerPlan?.nameWithProduct;
  }

  @action
  sendEvent() {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'on_open',
      object: 'pricing_tooltip',
      section: 'intershop',
      plan: this.args.plan.id,
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Intershop::Pricing::PricingSummary::Order::PricingSummary': typeof PricingSummaryComponent;
    'intershop/pricing/pricing-summary/order/pricing-summary': typeof PricingSummaryComponent;
  }
}
