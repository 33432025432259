/* import __COLOCATED_TEMPLATE__ from './tier-card.hbs'; */
/* RESPONSIBLE TEAM: team-purchase-experience */
import Component from '@glimmer/component';
import { type Block } from 'embercom/models/data/pricing/tier-types';
import { FIXED_DISCOUNT_OFFERED } from 'embercom/lib/intershop/intershop';

interface Args {
  isSelected: boolean;
  contractedPrice: number;
  isContractedAmount: boolean;
  isRecommended: boolean;
  showDiscount: boolean;
  dataBlock: Block;
  pricingMetric: string;
}
export default class TierCardComponent extends Component<Args> {
  get showDiscount(): boolean {
    return this.args.showDiscount && !this.args.isContractedAmount && !this.isIncluded;
  }

  get fixedDiscountOffered(): number {
    return FIXED_DISCOUNT_OFFERED;
  }

  get discountPrice(): number {
    let costDifference = this.args.dataBlock.cumulative_price - this.args.contractedPrice;
    // always round discount up to create more incentive to the user
    return Math.floor(
      this.args.dataBlock.cumulative_price - (costDifference / 100.0) * this.fixedDiscountOffered,
    );
  }

  get translationPricingMetric(): string {
    switch (this.args.pricingMetric) {
      case 'quarterly_active_people':
        return 'active_people';
      case 'thirty_day_messaged_contacts':
        return 'people_reached';
      case 'monthly_surveys_sent':
        return 'monthly_surveys_sent';
      case 'monthly_calls_deflected':
        return 'monthly_calls_deflected';
      case 'thirty_day_messaged_contacts_product_tours':
        return 'thirty_day_messaged_contacts_product_tours';
      default:
        // not supported by tier card
        return '';
    }
  }

  get isIncluded(): boolean {
    return this.args.dataBlock.cumulative_price === 0;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Intershop::Pricing::PricingMetrics::UsageSelector::TierSelector::TierCard': typeof TierCardComponent;
    'intershop/pricing/pricing-metrics/usage-selector/tier-selector/tier-card': typeof TierCardComponent;
  }
}
