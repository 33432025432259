/* import __COLOCATED_TEMPLATE__ from './add-on.hbs'; */
/* RESPONSIBLE TEAM: team-purchase-experience */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { SMS_ID } from 'embercom/lib/billing';
import type Product from 'embercom/models/product';

const addonsAddonRoute = `apps.app.intershop.add-ons.add-on.`;

interface Args {
  product: Product;
}

export default class AddOnComponent extends Component<Args> {
  @service declare appService: any;
  @service declare customerService: any;
  @service declare router: any;
  @service declare intershopService: any;
  @tracked scrollPosition = 0;

  get tabs(): { pricing: string; overview: string } {
    return {
      pricing: 'pricing',
      overview: 'index',
    };
  }

  get currentTab(): string {
    return this.router.currentRouteName.includes(this.tabs.pricing)
      ? this.tabs.pricing
      : this.tabs.overview;
  }

  get showTabs(): boolean {
    return this.args.product.id !== SMS_ID && this.intershopService.canAccessPricing;
  }

  @action
  changeRoute(route: string) {
    this.router.transitionTo(`${addonsAddonRoute}${route}`);
  }

  @action
  onScroll(scrollOffset: number) {
    this.scrollPosition = scrollOffset;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Intershop::Products::AddOns::AddOn': typeof AddOnComponent;
    'intershop/products/add-ons/add-on': typeof AddOnComponent;
  }
}
