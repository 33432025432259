/* import __COLOCATED_TEMPLATE__ from './intercomrade-warning.hbs'; */
/* RESPONSIBLE TEAM: team-frontend-tech */
import templateOnlyComponent from '@ember/component/template-only';

interface Signature {
  Blocks: {
    default: [];
  };
}

const IntercomradeWarning = templateOnlyComponent<Signature>();
export default IntercomradeWarning;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    IntercomradeWarning: typeof IntercomradeWarning;
    'intercomrade-warning': typeof IntercomradeWarning;
  }
}
