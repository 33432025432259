/* import __COLOCATED_TEMPLATE__ from './invite-team-members-link-component.hbs'; */
/* RESPONSIBLE TEAM: team-pricing-and-packaging */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import Component from '@ember/component';
import { computed } from '@ember/object';
import safeWindowOpen from 'embercom/lib/safe-window-open';

export default Component.extend({
  classNames: ['pl_invite_team_mates'],
  targetUrl: computed('app.id', function () {
    return `/a/apps/${this.app.id}/settings/teammates`;
  }),

  actions: {
    openSettingsInDifferentWindow() {
      safeWindowOpen(this.targetUrl, 'TeammateSettings');
    },
  },
});
