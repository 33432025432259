/* import __COLOCATED_TEMPLATE__ from './header.hbs'; */
/* RESPONSIBLE TEAM: team-purchase-experience */
/* eslint-disable @intercom/intercom/no-bare-strings */
import Component from '@glimmer/component';
import { planCardData, productCardData } from 'embercom/lib/intershop/intershop';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import type Product from 'embercom/models/product';
import type Plan from 'embercom/models/plan';
import type CatalogApi from 'embercom/services/catalog-api';
import { taskFor } from 'ember-concurrency-ts';

interface Args {
  product: Product;
  plan: Plan;
  contract: any;
}

export default class HeaderComponent extends Component<Args> {
  @service declare appService: any;
  @service declare catalogApi: CatalogApi;

  get productDetails() {
    return productCardData(this.args.product.id);
  }

  get planDetails() {
    return planCardData(this.args.plan.id);
  }

  get canRequestFreeTrialViaTicketing() {
    return (
      !this.args.product.isOnTrial &&
      this.higherTierPlanAvailable() &&
      !this.args.plan.billableCustomerPlan
    );
  }

  hasActivePlanOnProduct(): Plan | undefined {
    return this.args.product.billableCustomerPlan;
  }

  higherTierPlanAvailable() {
    if (!this.hasActivePlanOnProduct()) {
      return this.args.product.plans.firstObject;
    }
    return this.args.product.plans.objectAt(this.hasActivePlanOnProduct()!.tierId);
  }

  @action requestFreeTrial() {
    let params = {
      app_id: this.appService.app.id,
      product_name: `${this.args.product.name} ${this.higherTierPlanAvailable()?.name}`,
      current_plan: this.hasActivePlanOnProduct()
        ? `${this.args.product.name} ${this.hasActivePlanOnProduct()!.name}`
        : `No current plan on ${this.args.product.name}`,
      admin_id: this.appService.app.currentAdmin.id,
      admin_email: this.appService.app.currentAdmin.email,
    };
    taskFor(this.catalogApi.requestFreeTrial).perform(params);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Intershop::Products::CorePlans::Header': typeof HeaderComponent;
    'intershop/products/core-plans/header': typeof HeaderComponent;
  }
}
