/* import __COLOCATED_TEMPLATE__ from './feature.hbs'; */
/* RESPONSIBLE TEAM: team-purchase-experience */
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import {
  type IntershopProductFeature,
  type IntershopProductFeatureModal,
} from 'embercom/models/data/intershop/product-types';

interface Args {
  feature: IntershopProductFeature;
}

export default class FeatureComponent extends Component<Args> {
  @tracked isModalOpen = false;
  @service declare appService: any;

  get feature(): IntershopProductFeature {
    return this.args.feature;
  }

  get hasModal(): boolean {
    return this.feature.modal !== undefined;
  }

  get modalContent(): IntershopProductFeatureModal | undefined {
    return this.feature.modal;
  }

  get tooltipContent(): string {
    return this.feature.tooltip ?? '';
  }

  get hasTooltip(): boolean {
    return this.feature.tooltip !== undefined;
  }

  get icon(): string | undefined {
    return this.feature?.icon;
  }

  get isComingSoon(): boolean {
    if (this.feature.comingSoon === undefined) {
      return false;
    }
    // if feature flag is defined but user is not opted into it, then display coming soon
    return !this.appService.app.canUseFeature(this.feature.comingSoon);
  }
  @action
  onModalClose(): void {
    this.isModalOpen = false;
  }

  @action
  openModal(): void {
    if (!this.hasModal) {
      return;
    }
    this.isModalOpen = true;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Intershop::Products::Feature': typeof FeatureComponent;
    'intershop/products/feature': typeof FeatureComponent;
  }
}
