/* import __COLOCATED_TEMPLATE__ from './intercom-select-component.hbs'; */
/* RESPONSIBLE TEAM: team-frontend-tech */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable @intercom/intercom/no-component-inheritance */
/* eslint-disable ember/require-computed-property-dependencies */
/* eslint-disable ember/no-observers */
/* eslint-disable ember/no-jquery */
import { schedule } from '@ember/runloop';
import $ from 'jquery';
import { isPresent, isNone } from '@ember/utils';
import { computed, observer, get, action } from '@ember/object';
import { reads, and, notEmpty } from '@ember/object/computed';
import { ternary } from '@intercom/pulse/lib/computed-properties';
import SelectKeyboardActions from 'embercom/components/mixins/select-keyboard-actions';
import IntercomDropdownComponent from 'embercom/components/intercom-dropdown-component';

export default IntercomDropdownComponent.extend(SelectKeyboardActions, {
  classNames: ['f__custom-select'],
  classNameBindings: [
    'isInvisibleSelect:f__invisible-select',
    'isOpen:o__opened',
    'discreetMode:o__subtle',
  ],
  attributeBindings: ['tabindex'],
  tabindex: 0,
  selectableItems: reads('_mappedContent'),
  lightInvisibleSelect: and('isInvisibleSelect', 'discreetMode'),
  selectClass: ternary('isInvisibleSelect', 'f__invisible-select__box', 'f__custom-select__box'),
  selectBoxSelector: computed('selectClass', function () {
    return `.${this.selectClass}`;
  }),
  caretClass: ternary('isInvisibleSelect', 'f__invisible-select__caret', 'f__custom-select__caret'),

  value: null,
  selection: null,
  flipCaret: true,
  shouldFlipCaretAndIsOpen: and('flipCaret', 'isOpen'),

  isUsingSelection: notEmpty('selection'),

  selectedValue: computed('value', 'selection', 'content.[]', function () {
    if (this.isUsingSelection) {
      return this.selection;
    }
    if (isPresent(this.content)) {
      if (this.optionValuePath) {
        return this.content.findBy(this.optionValuePath, this.value);
      } else {
        return this.value;
      }
    }
  }),

  setSelection: observer({
    dependentKeys: ['value', 'content', 'isUsingSelection'],

    fn() {
      if (this.isUsingSelection) {
        let selection = this.content.findBy(this.optionValuePath, this.value);
        if (typeof selection !== 'undefined') {
          this.set('selection', selection);
        }
      }
    },

    sync: true,
  }),

  content: null,

  optionIconPath: 'icon',
  optionDescriptionPath: 'description',
  optionTooltipPath: 'tooltip',
  optionLabelPath: null,
  optionValuePath: null,

  disabled: false,
  shouldSelectFirstItemOnCreate: false,
  isInvisibleSelect: false,

  didInsertElement() {
    this._super(...arguments);
    this._maybeSetValueToFirstItem();
  },

  setDefaultValue: observer({
    dependentKeys: ['isVisible'],

    fn() {
      if (this.isVisible) {
        this._maybeSetValueToFirstItem();
      }
    },

    sync: true,
  }),

  onOpenStateChange: observer({
    dependentKeys: ['isOpen'],

    fn() {
      if (this.isOpen) {
        this._calculateDropdownPositioning();
        this._scrollToSelectedItem();
      }
    },

    sync: true,
  }),

  _maybeSetValueToFirstItem() {
    if (this.shouldSelectFirstItemOnCreate && this.get('content.length') && isNone(this.value)) {
      this.send('setSelectedOption', 0);
    }
  },

  _mappedValue: computed('selectedValue', function () {
    let value = this.selectedValue;
    if (isPresent(value)) {
      return this._createMappedValueForItem(value);
    }
  }),

  _mappedContent: computed('content.[]', 'value', function () {
    let content = this.content;
    if (isPresent(content)) {
      return content.map(
        function (item) {
          return this._createMappedValueForItem(item);
        }.bind(this),
      );
    }
  }),

  _createMappedValueForItem(item) {
    let optionLabelPath = this.optionLabelPath;
    if (optionLabelPath) {
      let value = get(item, this.optionValuePath);
      return {
        label: get(item, optionLabelPath),
        value,
        icon: get(item, this.optionIconPath),
        description: get(item, this.optionDescriptionPath),
        tooltip: get(item, this.optionTooltipPath),
        isSelected: value === this.value,
      };
    } else {
      return {
        label: item,
        value: item,
        icon: null,
        description: null,
        tooltip: null,
        isSelected: item === this.value,
      };
    }
  },

  _scrollToSelectedItem() {
    schedule('afterRender', this, function () {
      let $dropdown = $('.dropdown', this.element);
      let $selectedItem = $dropdown.find('.dropdown__list-item-selected');
      if ($selectedItem.length) {
        $dropdown.scrollTop($selectedItem.position().top);
      }
    });
  },

  _handleSelection(selectedValue) {
    this.set('isOpen', false);
    this.set('value', selectedValue);
  },

  setSelectedOptionAction: action(function (index) {
    let optionValuePath = this.optionValuePath;
    if (optionValuePath) {
      this._handleSelection(get(this.content.objectAt(index), optionValuePath));
    } else {
      this._handleSelection(this.content.objectAt(index));
    }
  }),

  actions: {
    setSelectedOption(index) {
      this.setSelectedOptionAction(index);
    },
    selectItem() {
      this.send('setSelectedOption', this.highlightedItemIndex);
    },
  },
});
