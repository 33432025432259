/* import __COLOCATED_TEMPLATE__ from './footer.hbs'; */
/* RESPONSIBLE TEAM: team-purchase-experience */
/* eslint-disable @intercom/intercom/no-bare-strings */
import Component from '@glimmer/component';
import { addonData } from 'embercom/lib/intershop/intershop';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { SURVEYS_ID, SWITCH_ID } from 'embercom/lib/billing';
import type Product from 'embercom/models/product';
import type Plan from 'embercom/models/plan';

interface Args {
  product: Product;
  plan: Plan;
}

const PRODUCTS_WITH_EXAMPLE = [SURVEYS_ID, SWITCH_ID];
export default class FooterComponent extends Component<Args> {
  @service declare intercomEventService: any;

  SWITCH_ID = SWITCH_ID;

  @tracked exampleModalOpen = false;

  get productData() {
    return addonData(this.args.plan.id);
  }

  get assumedPlan() {
    return this.args.product.plans.firstObject;
  }

  get hasExample() {
    return PRODUCTS_WITH_EXAMPLE.includes(this.args.product.id);
  }

  get productName() {
    // special case where product name is being changed to reflect tooltips
    return this.args.product.name === 'Product Tours'
      ? 'Product Tours and Tooltips'
      : this.args.product.name;
  }

  @action
  viewExample() {
    let data = addonData(this.args.plan.id);

    switch (this.args.product.id) {
      case SWITCH_ID:
        this.exampleModalOpen = true;
        return;
      case SURVEYS_ID:
        // @ts-ignore
        Intercom(data.example.action, data.example.id);
        return;
    }
  }

  @action
  onViewExampleModalClose() {
    this.exampleModalOpen = false;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Intershop::Products::AddOns::Overview::Footer': typeof FooterComponent;
    'intershop/products/add-ons/overview/footer': typeof FooterComponent;
  }
}
