/* import __COLOCATED_TEMPLATE__ from './intercomrades-package-editor-modal.hbs'; */
/* RESPONSIBLE TEAM: team-frontend-tech */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/no-classic-classes */
import EmberObject, { action, computed } from '@ember/object';
import { map, equal } from '@ember/object/computed';
// eslint-disable-next-line ember/no-classic-components
import Component from '@ember/component';
import { inject as service } from '@ember/service';
import { alias } from '@ember/object/computed';

export default Component.extend({
  tagName: '',
  // eslint-disable-next-line @intercom/intercom/no-legacy-modal
  modalService: service(),
  appService: service(),
  app: alias('appService.app'),
  canCloseModal: true,
  products: map('app.products', function (product) {
    return EmberObject.create({
      name: product.get('name'),
      activePlanId: 'none',
      hasOnePlan: product.get('hasOnePlan'),
      plans: product.get('plans').map((plan) => {
        return EmberObject.create({
          id: plan.get('id'),
          name: plan.get('name'),
        });
      }),
    });
  }),

  choice: 'subscription',
  choiceIsSubscription: equal('choice', 'subscription'),
  enabledKey: computed('app.id', function () {
    return `icp-${this.app.id}-enabled`;
  }),
  planIdsKey: computed('app.id', function () {
    return `icp-${this.app.id}-plan-ids`;
  }),

  init() {
    this._super(...arguments);
    this.setFromLocalStorage();
  },

  willDestroyElement() {
    this._super(...arguments);
  },

  setFromLocalStorage() {
    if (localStorage.getItem(this.enabledKey) === 'true') {
      this.set('choice', 'customize');
    }

    let planIds = JSON.parse(localStorage.getItem(this.planIdsKey));
    if (!planIds) {
      return;
    }

    planIds.forEach((planId) => {
      let product = this.products.find((product) => {
        return product.get('plans').findBy('id', planId);
      });

      product.set('activePlanId', planId);
    });
  },

  storeToLocalStorage() {
    if (this.choiceIsSubscription) {
      localStorage.setItem(this.enabledKey, false);
    } else {
      localStorage.setItem(this.enabledKey, true);

      let key = this.planIdsKey;
      let planIds = this.products.mapBy('activePlanId').compact();
      planIds = planIds.filter((planId) => planId !== 'none');

      localStorage.setItem(key, JSON.stringify(planIds));
    }
  },

  closeModal: action(function () {
    this.modalService.closeModal();
  }),

  onSubmit: action(function () {
    this.set('isSaving', true);
    this.storeToLocalStorage();
    window.location.reload();
  }),
});
