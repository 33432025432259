/* import __COLOCATED_TEMPLATE__ from './usage-based.hbs'; */
/* RESPONSIBLE TEAM: team-purchase-experience */
import Component from '@glimmer/component';
import type PricingMetric from 'embercom/lib/purchase/pricing-metric';
import { type Metric } from 'embercom/models/data/pricing/metric-types';
import { type Block } from 'embercom/models/data/pricing/tier-types';
import type Product from 'embercom/models/product';
import { FIXED_DISCOUNT_OFFERED } from 'embercom/lib/intershop/intershop';
import { inject as service } from '@ember/service';
import type IntershopService from 'embercom/services/intershop-service';
import { FIN_PRICE_PER_RESOLUTION } from 'embercom/lib/billing';

interface Args {
  product: Product;
  blocks: Array<Block>;
  pricingMetric: PricingMetric;
  onChange: (seats: number, metric: Metric) => {};
  isPlanInSubscription: boolean;
  isPlanOnTrial: boolean;
  hasActivePlanOnProduct: boolean;
}

export default class UsageBasedComponent extends Component<Args> {
  @service declare intershopService: IntershopService;
  @service declare appService: any;

  get contractedUsage(): number {
    return this.intershopService.contractUsageLimits[this.args.pricingMetric.metric];
  }

  get hasSecondaryWorkspace(): boolean {
    return this.intershopService.contract.numberOfSecondarySubscriptions > 0;
  }

  get totalUsage(): number {
    return this.intershopService.totalUsage[this.args.pricingMetric.metric];
  }

  get finPricePerResolution(): number {
    return FIN_PRICE_PER_RESOLUTION;
  }

  get discountPercentage(): number {
    return FIXED_DISCOUNT_OFFERED;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Intershop::Pricing::PricingMetrics::UsageBased': typeof UsageBasedComponent;
    'intershop/pricing/pricing-metrics/usage-based': typeof UsageBasedComponent;
  }
}
