/* import __COLOCATED_TEMPLATE__ from './feature-modal.hbs'; */
/* RESPONSIBLE TEAM: team-purchase-experience */
import Component from '@glimmer/component';
import { type IntershopProductFeatureModal } from 'embercom/models/data/intershop/product-types';

const VIDEO_FILE_EXTENSIONS = ['mp4', 'webm'];
const IMAGE_FILE_EXTENSIONS = ['png', 'jpg', 'gif', 'svg', 'webp'];

interface Args {
  onModalClose: () => void;
  isOpen: boolean;
  content: IntershopProductFeatureModal;
}

export default class FeatureModalComponent extends Component<Args> {
  get heading(): string {
    return this.args.content.heading;
  }

  get description(): string | undefined {
    return this.args.content.description;
  }

  get isVideo(): boolean {
    return VIDEO_FILE_EXTENSIONS.includes(this.extension);
  }

  get isImage(): boolean {
    return IMAGE_FILE_EXTENSIONS.includes(this.extension);
  }

  get extension(): string {
    return this.url.split('.').pop() ?? '';
  }

  get url(): string {
    return this.args.content.mediaUrl;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Intershop::Modals::Products::FeatureModal': typeof FeatureModalComponent;
    'intershop/modals/products/feature-modal': typeof FeatureModalComponent;
  }
}
