/* import __COLOCATED_TEMPLATE__ from './pricing.hbs'; */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* RESPONSIBLE TEAM: team-purchase-experience */
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import PricingMetric from 'embercom/lib/purchase/pricing-metric';
import { action } from '@ember/object';
import { keepLatestTask, enqueueTask } from 'ember-concurrency-decorators';
import { tracked } from '@glimmer/tracking';
import { type Block } from 'embercom/models/data/pricing/tier-types';
import { Metric } from 'embercom/models/data/pricing/metric-types';
import { type TaskGenerator } from 'ember-concurrency';
import { taskFor } from 'ember-concurrency-ts';
import type CatalogApi from 'embercom/services/catalog-api';
import type IntershopService from 'embercom/services/intershop-service';
import type Plan from 'embercom/models/plan';
import type Product from 'embercom/models/product';
import type Store from '@ember-data/store';
import { PRICING_METRIC_METADATA } from 'embercom/lib/billing';
import { generateBlocksFromTiers } from 'embercom/lib/intershop/intershop';

interface Args {
  product: Product;
  plan: Plan;
  peopleReachedPlanIds: number;
}

export default class PricingComponent extends Component<Args> {
  @service declare store: Store;
  @service declare catalogApi: CatalogApi;
  @service declare intershopService: IntershopService;
  @service customerService: any;
  @service declare appService: any;
  @tracked showPricingSummaryModal = false;
  @tracked selectedMetricsUsage: Partial<Record<Metric, number>> = {};
  @tracked metricDiscountOffered: Partial<Record<Metric, number>> = {};
  @tracked disableCheckoutButton = false;

  constructor(owner: unknown, args: Args) {
    super(owner, args);
    this.intershopService.setPlan(this.args.plan);
    let currentBillablePeopleReachedId = this.args.peopleReachedPlanIds
      ? this.intershopService.hasBillablePeopleReachedTierId()
      : undefined;
    taskFor(this.fetchPriceWithUsage).perform(
      this.args.product.billableCustomerPlan?.idAsNumber ?? this.args.plan.idAsNumber,
      true,
      currentBillablePeopleReachedId,
    );
    taskFor(this.fetchPriceWithUsage).perform();
  }

  get pricingModelIdentifier() {
    return this.args.plan.price?.pricingModelIdentifier;
  }

  get pricingMetrics(): Array<PricingMetric> {
    let planIds = [this.args.plan.idAsNumber, this.args.peopleReachedPlanIds].compact();

    let metrics = planIds.flatMap((id) => {
      return this.customerService
        .getPriceFromPlanIds([id], this.pricingModelIdentifier)
        .breakdown.firstObject.charges.toArray();
    });

    return (
      metrics
        .map((metric) => {
          metric = new PricingMetric(metric);
          metric.blocks = !metric.isSeatMetric ? this.generateBlocks(metric) : [];
          // @ts-ignore
          metric.metaData = PRICING_METRIC_METADATA[metric] ?? null;
          return metric;
        })
        // remove this filter once Fin is released to all
        .filter((metric) => {
          return !(
            metric.metric === Metric.resolutions && !this.intershopService.hasIntershopFinAccess
          );
        })
    );
  }

  setCheckoutButtonStatus() {
    if (!this.args.plan.billableCustomerPlan) {
      this.disableCheckoutButton = false;
      return;
    }
    let selectionEqualsContracted = Object.keys(this.selectedMetricsUsage).map((key: Metric) => {
      return (
        (this.intershopService.getMetricContractedValue(key) === 0 &&
          this.selectedMetricsUsage[key] === 0) ||
        this.selectedMetricsUsage[key] === this.intershopService.getMetricContractedValue(key)
      );
    });
    this.disableCheckoutButton = selectionEqualsContracted.every((value) => value === true);
  }

  @action
  setDollarAmountDiscount(): void {
    this.intershopService.setDiscountDollarAmount(this.metricDiscountOffered);
  }

  @action
  openPricingSummaryModal(): void {
    this.showPricingSummaryModal = true;
  }

  @action
  closePricingSummaryModal(): void {
    this.showPricingSummaryModal = false;
  }

  @action
  onUsageBasedMetricSummaryChange(index: number, metric: Metric, dollarDiscountValue: number) {
    if (this.intershopService.isDiscountAvailable(metric)) {
      this.metricDiscountOffered[metric] = dollarDiscountValue;
    }
    this.selectedMetricsUsage[metric] = this.usageIndexToTierUnitValue(metric, index);
    this.selectedMetricsUsage = this.selectedMetricsUsage;
    this.setCheckoutButtonStatus();
    taskFor(this.fetchPriceWithUsage).perform();
    this.setDollarAmountDiscount();
  }

  @action
  onStaticUsageBasedMetricUpdate(usage: number, metric: Metric) {
    this.selectedMetricsUsage[metric] = usage;
    this.selectedMetricsUsage = this.selectedMetricsUsage;
    this.setCheckoutButtonStatus();
  }

  @action
  onSeatBasedMetricSummaryChange(seats: number, metric: Metric, dollarDiscountValue: number) {
    let contractedSeatsPlusSelectedSeats = seats;
    if (this.intershopService.isDiscountAvailable(metric)) {
      this.metricDiscountOffered[metric] = seats * dollarDiscountValue;
    }
    if (
      this.args.product.billableCustomerPlan &&
      !this.intershopService.planHasOverlappingMetricsAndIsAddon
    ) {
      contractedSeatsPlusSelectedSeats += this.intershopService.getMetricContractedValue(metric);
    }
    this.selectedMetricsUsage[metric] = contractedSeatsPlusSelectedSeats;
    this.selectedMetricsUsage = this.selectedMetricsUsage;
    this.setCheckoutButtonStatus();
    taskFor(this.fetchPriceWithUsage).perform();
    this.setDollarAmountDiscount();
  }

  private requestedInclusiveProductUageString(): string {
    return Object.keys(this.selectedMetricsUsage)
      .map((key: Metric) => `Metric: ${key}, Usage: ${this.selectedMetricsUsage[key]} \n`)
      .join('');
  }

  private requestedAdditionalProductUageString(): string {
    return Object.keys(this.selectedMetricsUsage)
      .map(
        (key: Metric) =>
          `Metric: ${key}, Usage: ${
            this.selectedMetricsUsage[key]! - this.intershopService.getMetricContractedValue(key)
          } \n`,
      )
      .join('');
  }

  private additionalRevenueWithoutDiscount(): number {
    return Object.keys(this.selectedMetricsUsage).reduce((revenue: number, key: Metric) => {
      let pricingMetric = this.pricingMetrics.find((metric) => metric.metric === key);

      if (pricingMetric?.isSeatMetric) {
        // Get accumalative price per unit for seats of metric from pricing response
        let seatCharges = this.intershopService.newPriceBreakdown.reduce(
          (accumulator: number, breakdown: any) => {
            let hasCharges = breakdown.charges.find((charge: any) => charge.pricing_metric === key);
            if (hasCharges) {
              return accumulator + this.intershopService.centsToDollars(hasCharges.per_unit_price);
            }
            return accumulator;
          },
          0,
        );
        let additionalSeats =
          this.selectedMetricsUsage[key]! - this.intershopService.getMetricContractedValue(key);
        revenue += additionalSeats > 0 ? additionalSeats * seatCharges : 0;
      } else {
        let currentTier =
          this.usageToIndex(key, this.intershopService.getMetricContractedValue(key)) || 0;
        let selectedTier = this.usageToIndex(key, this.selectedMetricsUsage[key]!) || 0;
        let additionalPrice =
          (pricingMetric?.blocks?.[selectedTier]?.cumulative_price || 0) -
          (pricingMetric?.blocks?.[currentTier]?.cumulative_price || 0);
        revenue += additionalPrice > 0 ? additionalPrice : 0;
      }
      return revenue;
    }, 0);
  }

  @enqueueTask
  *requestSubscriptionAmendment(): TaskGenerator<void> {
    let productWithPlanName = this.args.product.addon
      ? `${this.args.plan.name}`
      : `${this.args.product.name} ${this.args.plan.name}`;
    let viewingABillableCustomerPlan = this.args.plan.billableCustomerPlan;

    // New usage is the usage the customer has requested minus the contracted usage
    // Because this.selectedMetricsUsage includes the contracted plus requested usage
    let newUsage = this.args.product.billableCustomerPlan
      ? this.requestedAdditionalProductUageString()
      : this.requestedInclusiveProductUageString();

    // New revenue is the cost of the additional usage minus the discount for both seats and usage based metrics
    // If it's a net new plan or an ugrade it is just the cost differenece minus the discount
    let newRevenue = viewingABillableCustomerPlan
      ? this.additionalRevenueWithoutDiscount() + this.intershopService.dollarAmountDiscount
      : this.intershopService.costDifferenceMinusDiscount;

    let currentBillablePlan = this.args.product.billableCustomerPlan
      ? `${this.args.product.name} ${this.args.product.billableCustomerPlan.name}`
      : '';

    // -------------- TODO --------------
    // Going to be current cost - new cost find % of discount offered
    // Need to reverse engineer this
    let discountAsPercentage = viewingABillableCustomerPlan
      ? ((this.intershopService.dollarAmountDiscount * -1) /
          this.additionalRevenueWithoutDiscount()) *
        100
      : 0;

    let params = {
      app_id: this.appService.app.id,
      product_name: productWithPlanName,
      admin_id: this.appService.app.currentAdmin.id,
      admin_email: this.appService.app.currentAdmin.email,
      product_usage: this.requestedInclusiveProductUageString(),
      quoted_cost: this.intershopService.costDifferenceMinusDiscount,
      discount: viewingABillableCustomerPlan ? this.intershopService.dollarAmountDiscount : 0,
      new_net_usage: newUsage,
      new_net_revenue: newRevenue,
      current_plan_name: currentBillablePlan,
      discount_percentage: Number(discountAsPercentage.toFixed(4)),
    };

    yield taskFor(this.catalogApi.requestSubscriptionAmendment).perform(params);
    this.closePricingSummaryModal();
  }

  @keepLatestTask
  *fetchPriceWithUsage(
    planId: number = this.args.plan.idAsNumber,
    setInitalPrice = false,
    peopleReachedId: number = this.args.peopleReachedPlanIds,
  ) {
    // Need to check if this.selectedMetricsUsage is lower than any overages that exist
    // If lower set copy of this.selectedMetricsUsage to billedUsage
    let tempSelectedMetricsUsage: Partial<Record<Metric, number>> = {
      ...this.selectedMetricsUsage,
    };
    Object.keys(tempSelectedMetricsUsage).map((key: Metric) => {
      if (this.intershopService.getMetricBilledUsage(key) > this.selectedMetricsUsage[key]!) {
        tempSelectedMetricsUsage[key] = this.intershopService.getMetricBilledUsage(key);
      }
    });

    let planIds = [planId, peopleReachedId];

    // Only use overlapping plan ids for pricing call if it's a core product plan and not an addon
    if (!this.args.product.addon) {
      planIds.push(...this.customerService.getPlansWithOverlappingPricingMetrics(this.args.plan));
    }

    yield taskFor(this.intershopService.requestPricesWithUsage).perform(
      {
        app_id: this.appService.app.id,
        plan_ids: planIds.compact(),
        pricing_model_identifier: this.pricingModelIdentifier,
        source: 'intershop-pricing',
        ...(setInitalPrice ? this.intershopService.getBilledUsage() : tempSelectedMetricsUsage),
      },
      setInitalPrice,
    );
  }

  // prevent selecting lower tier than in contracted by removing blocks below current contracted amount
  private generateBlocks(metric: PricingMetric): Array<Block> {
    let contractedUsage = this.intershopService.getMetricContractedValue(metric.metric);
    let blocks = generateBlocksFromTiers(
      metric,
      this.intershopService.pricingMetricLimit(metric.metric),
    );

    if (contractedUsage === 0) {
      return blocks;
    }

    let index = blocks.findIndex(
      (block) => block.starting_unit <= contractedUsage && block.ending_unit >= contractedUsage,
    );

    return blocks.slice(index);
  }

  private usageToIndex(metric: any, usage: number) {
    return this.pricingMetrics
      .find((pricingMetric) => pricingMetric.metric === metric)
      ?.blocks?.findIndex((block) => block.starting_unit <= usage && block.ending_unit >= usage);
  }

  private usageIndexToTierUnitValue(metric: any, index: number) {
    return (
      this.pricingMetrics.find((pricingMetric) => pricingMetric.metric === metric)?.blocks?.[index]
        .ending_unit || 0
    );
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Intershop::Pricing::Pricing': typeof PricingComponent;
    'intershop/pricing/pricing': typeof PricingComponent;
  }
}
