/* import __COLOCATED_TEMPLATE__ from './intershop-home.hbs'; */
/* RESPONSIBLE TEAM: team-purchase-experience */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { CORE_ENGAGE_ID, PROACTIVE_SUPPORT_ID } from 'embercom/lib/billing';
import { categorizeAddons, includedProductIds } from 'embercom/lib/intershop/intershop';
import type Product from 'embercom/models/product';

interface Args {
  products: Array<Product>;
}

export default class IntershopHome extends Component<Args> {
  @service declare appService: any;
  @service declare customerService: any;

  constructor(owner: unknown, args: Args) {
    super(owner, args);
  }

  get products(): Array<Product> {
    let hasBillableEngageProduct = this.appService.app.activeProducts.some(
      (product: Product) => product.id === CORE_ENGAGE_ID,
    );
    let hasBillingProactiveSupportProduct = this.appService.app.activeProducts.some(
      (product: Product) => product.id === PROACTIVE_SUPPORT_ID,
    );

    let products = this.args.products.filter((product) =>
      includedProductIds().includes(product.id),
    );

    if (hasBillableEngageProduct && !hasBillingProactiveSupportProduct) {
      products = products.filter((product) => product.id !== PROACTIVE_SUPPORT_ID);
    }
    return products;
  }

  get addons(): Array<Product> {
    return this.products.filter((product) => product.addon);
  }

  get addonGroups(): any {
    let includeCopilot = this.appService.app?.canUseFinAiCopilotAddon;
    return categorizeAddons(this.addons, includeCopilot);
  }

  get primaryProducts(): Array<Product> {
    return this.products.filter((product) => !product.addon);
  }

  get onlyOnePrimaryProduct(): boolean {
    return this.primaryProducts.length === 1;
  }

  get isSalesforceContracted(): boolean {
    return this.appService.app.isSalesforceContracted;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Intershop::Home::IntershopHome': typeof IntershopHome;
    'intershop/home/intershop-home': typeof IntershopHome;
  }
}
