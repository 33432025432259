/* import __COLOCATED_TEMPLATE__ from './homepage.hbs'; */
/* RESPONSIBLE TEAM: team-purchase-experience */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import type IntlService from 'embercom/services/intl';
import { get } from 'embercom/lib/ajax';
import { keepLatestTask } from 'ember-concurrency-decorators';
import { taskFor } from 'ember-concurrency-ts';
import { type TaskGenerator } from 'ember-concurrency';

interface Args {
  recommendations: Array<any>;
}

export default class HomepageComponent extends Component<Args> {
  @service declare intl: IntlService;
  @service declare appService: any;
  @tracked openSectionId = '';
  @tracked recommendations: any;
  @tracked recommendationToggleText: string;

  constructor(owner: unknown, args: Args) {
    super(owner, args);
    taskFor(this.fetchRecommendations).perform();
    this.recommendationToggleText = this.intl.t('intershop.recommendations.show_additional_cta');
  }

  get limitedRecommendations(): any {
    return this.reduceRecommendationArray(Object.keys(this.recommendations).slice(0, 2));
  }

  get extendedRecommendations(): any {
    return this.reduceRecommendationArray(Object.keys(this.recommendations).slice(2));
  }

  get hasExtendedRecommendations(): boolean {
    return this.extendedRecommendations && Object.keys(this.extendedRecommendations).length > 0;
  }

  get hasRecommendations(): boolean {
    return this.recommendations && Object.keys(this.recommendations).length;
  }

  @keepLatestTask
  *fetchRecommendations(): TaskGenerator<void> {
    let response = yield get('/ember/expansion_opportunities', {
      app_id: this.appService.app.id,
    });

    if (response?.opportunity_product_plan_map) {
      this.recommendations = Object.keys(response?.opportunity_product_plan_map).length
        ? response.opportunity_product_plan_map
        : {};
    }
  }

  private reduceRecommendationArray(recommendations: Array<any>) {
    return recommendations.reduce((result: any, key: any) => {
      result[key] = this.recommendations[key];
      return result;
    }, {});
  }

  @action
  onOpenSectionChange(sectionId: any) {
    if (this.openSectionId === 'extended_recommendations') {
      this.openSectionId = '';
      this.recommendationToggleText = this.intl.t('intershop.recommendations.show_additional_cta');
    } else {
      this.openSectionId = sectionId;
      this.recommendationToggleText = this.intl.t('intershop.recommendations.hide_additional_cta');
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Intershop::Recommendations::Homepage': typeof HomepageComponent;
    'intershop/recommendations/homepage': typeof HomepageComponent;
  }
}
